import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bgImage from "@images/resources/footer-bg-shape-1.png";
import footerLogo from "@images/resources/footer-logo.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="site-footer">
      <div
        className="site-footer-bg"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={12}>
            <div className="footer-upper text-center">
              <div className="footer__logo">
                信查查
              </div>
              {/*<div className="footer-logo">*/}
              {/*  <img src={footerLogo} alt="Footer Logo" />*/}
              {/*</div>*/}
              {/*<div className="footer-menu">*/}
              {/*  <ul>*/}
              {/*    <li>*/}
              {/*      <a href="#">Home</a>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <a href="#">Features</a>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <a href="#">Pricing</a>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <a href="#">Team</a>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <a href="#">Screens</a>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <a href="#">News</a>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="site-footer__bottom">
        <Container className="text-center">
          <p>
              <a href="https://beian.miit.gov.cn/">ICP备案 粤ICP备16096032号-1</a>
          </p>
          <p>
            © copyright {year} by 广东万惠网络科技集团有限公司
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
